@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed|Monda:700&display=swap');


html body .app.flex-row.align-items-center {
  height: 100vh;
}

.sidebar {
  background: #3a4149;
  border-right: 1px solid #23282c;
}
.sidebar .nav-link .nav-icon {
  display: inline-block;
  width: 1.09375rem;
  margin: 0 0.5rem 0 0;
  font-size: 0.875rem;
  color: #b8babc7d;
  text-align: center;
}
.sidebar .nav-link.active .nav-icon {
  color: #ffffff;
}
.sidebar .nav-link:hover {
  color: #fff;
  background: #ff4645;
}
.sidebar .nav-link.active {
  background: #ef3937;
}
a {
  color: #d82020;
  text-decoration: none;
  background-color: transparent;
}
@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link {
    background: #d82020;
  }
}

.scrollbar
{
  margin-left: 30px;
  float: left;
  height: 200px;
  width: 65px;
  background: #F5F5F5;
  overflow-y: scroll;
  margin-bottom: 25px;
}
.custom-control {
  min-height: 1.400rem;
  font-size: 13px;
  font-family: 'IBM Plex Sans Condensed', sans-serif;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #f36767;
  border-color: #ffa6a5;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ef3937;
  border-color: #f7a3a2;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #f7a3a2;
  background-color: #ef3937;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
  background-color: #333;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background-color: #575757;
  border-radius: 10px;
} /* the new scrollbar will have a flat appearance with the set background color */

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
} /* this will style the thumb, ignoring the track */

.breadcrumb {
  line-height: 16px;
  border-bottom: 1px solid #23282c;
  background-color: #3a4149;

}
.breadcrumb-item.active {
  color: #fff;
}



.logo-Risk{
  color: white;
  font-weight: 600;
  font-size: 29px;
}
.color-logo{
  color: red;
  font-weight: 600;
  font-size: 29px;
}

.mia{
  width: 26px;
  height: 24px;
  margin-right: 14px;
}
.contenido-logo-resk{
  align-content: center;
}

.app-header {
  background-color: #3a4149;
  border-bottom: 1px solid #23282c;
}

// ie11 floating footer temp fix, react only
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #root {
    display: flex;
    flex-direction: column;
  }
}

